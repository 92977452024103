import React from "react";
import styled from "styled-components";

const NAV = styled.nav`
    font-family: "Inter";
    color: var(--lightgrey);
    font-weight: 400;
    font-size: var(--medium);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Ul = styled.ul`
    list-style-type: none;
    margin-top: 30px;
`;

const Ul2 = styled.div`
    list-style-type: none;
    margin-top: 30px;
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
    width: 80vw;
`;

/*
    @media (orientation: portrait) and (max-width: 500px) {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-left: 0;
    }
*/

const ListItem = styled.li`
    font-weight: 600;
    color: var(--white);
    font-size: var(--bigger);
`;

const GridItem = styled.div`
    grid-column: 1 / -1;
    grid-row: 1;
    height: 10vh;
`;

const Anchor = styled.a`
    font-size: var(--smol);
    font-weight: 600;
    color: var(--orange);
    letter-spacing: 4px;
    @media (orientation: portrait) and (max-width:500px) {
        font-size: var(--vsmol);
    }
    text-decoration: inherit;
`;

const Nav = () => (
    <GridItem>
        <NAV>
            <Ul2>
                <Anchor href="#role">ROLE</Anchor>
                <Anchor href="#about">ABOUT</Anchor>
                <Anchor href="#skills">SKILLS</Anchor>
                <Anchor href="#blog">BLOG</Anchor>
            </Ul2>
        </NAV>
    </GridItem>
)


export { Ul, ListItem };
export default Nav;

// <Anchor href="#projects">PROJECTS</Anchor>