import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
    position: absolute;
    z-index: -1;
    width: 108vw;
    top: 4.5%;
`;

// const CenterLine = styled.div`
//     position: fixed;
//     width: 100vw;
//     height: 1px;
//     background-color: green;
//     top: 50%;
// `;

const Wave = () => (
    <SVG viewBox="-0.19035299122333527 178.5427703857422 1596.1903076171875 416.8070068359375" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-0.123459 335.329C30.4127 350.913 50.0001 3.18483 106.5 350.776C120.761 438.508 166 709.952 186.5 482.205C191.964 421.502 215.08 117.543 245.54 350.776C276 584.01 358 214.717 427.5 335.329C497 455.941 468.5 562.328 576.5 350.776C684.5 139.224 1327.6 541.641 1596 350.776" stroke="#FF8311" strokeOpacity="0.5"></path>
        <path d="M-0.190353 318.128C30.3459 333.712 49.9332 -14.0157 106.433 333.576C120.694 421.308 165.933 692.752 186.433 465.004C191.897 404.302 215.013 100.342 245.473 333.576C275.933 566.809 357.933 197.516 427.433 318.128C496.933 438.741 468.433 545.128 576.433 333.576C684.433 122.024 1327.53 524.441 1595.93 333.576" stroke="#8D8D8D" strokeOpacity="0.5"></path>
        <path d="M-0.190353 359.578C30.3459 375.162 49.9332 27.4337 106.433 375.025C120.694 462.757 165.933 734.201 186.433 506.453C191.897 445.751 215.013 141.792 245.473 375.025C275.933 608.259 357.933 238.965 427.433 359.578C496.933 480.19 468.433 586.577 576.433 375.025C684.433 163.473 1327.53 565.89 1595.93 375.025" stroke="#8D8D8D" strokeOpacity="0.5"></path>
    </SVG>
)

export default Wave;