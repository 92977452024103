import React, { useRef, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components";
import sr from "@utils/scrollreveal";
import { Heading, ProjectCard } from "@styles";

const Div = styled.div`
    height: 100vh;
    grid-column: 2 / span 3;
    position: relative;
    display: grid;
`;

const Container = styled.div`
    align-self: center;
    position: relative;
    height: 721px;
    overflow-x: hidden;
`;  

const ProjectsContainer = styled.div`
    position: absolute;
    margin-top: 20px;
    display: flex;
    gap: 30px;
    overflow-x: scroll;
`;

const Anchor = styled.a`
    font-size: var(--big);
    font-weight: 400;
    color: var(--orange);
    letter-spacing: 4px;
    text-decoration: inherit;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Div2 = styled.div`
    margin-right: 20px;
`;



const Projects = ({ data }) => {
    const stuff = data[0].node;
    const { heading } = stuff.frontmatter;

    const [projectsPosition, setProjectsPosition] = useState("0");

    const moveProjects = (direction) => {
        if (direction === "left") {
            setProjectsPosition(projectsPosition + 20);
        }
    
        if (direction === "right") {
            setProjectsPosition(projectsPosition - 20);
        }
    }

    const scrollReveal = useRef(null);
    useEffect(() => sr.reveal(scrollReveal.current), []);

    const projectsQuery = useStaticQuery(graphql`
        query MyQuery {
            allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/work-projects/"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
            edges {
                node {
                frontmatter {
                    company
                    logo
                    maintext
                }
                excerpt
                }
            }
            }
        }
    `);

    const projects = projectsQuery.allMarkdownRemark.edges;

    const projectsContainer = useRef(null);

    return (
        <Div id="projects" ref={scrollReveal}>
            <Container>
                <Flex>
                    <Heading>{heading}</Heading>
                    <Div2>
                        <Anchor onClick={() => moveProjects("left")}>&lt; </Anchor>
                        <Anchor onClick={() => moveProjects("right")}> &gt;</Anchor>
                    </Div2>
                </Flex>
                
                <ProjectsContainer style={{ marginLeft: projectsPosition }}>
                    {projects.map((project) => (
                        <ProjectCard key={project.node.frontmatter.company} data={project.node} />
                    ))}
                </ProjectsContainer>
            </Container>
        </Div>
    );
}

export default Projects;